import { useEffect, useState } from "react";
import Footer from "../../components/layouts/footer";
import { API_URL, URLS } from "../../_config";
import { useToken } from "../../_common/hooks/authHook";
import toast from "react-hot-toast";
import Nav from "../../components/layouts/nav";
import { Link, useParams } from "react-router-dom";
import Icon from "../../components/icon";
import { replaceUrlParams } from "../../_common/functions";

const MyBusinessPlanDetails = () => {
    const [myBusinessPlan, setMyBusinessPlan] = useState<any>(null);
    const [addonsIds, setAddonsIds] = useState<any>(null);
    let tokenData: any = useToken();

    const urlParams: any = useParams();
    const proposalId = urlParams?.proposalId;

    const getMyBusinessPlanDetails = async () => {
        try {
            const response = await fetch(API_URL.ACCOUNT.MY_BUSINESS_PLAN_DETAILS + "/" + proposalId, {
                headers: { Authorization: `Bearer ${tokenData}` }
            });
            const resp = await response.json();
            console.log(resp.data);
            setMyBusinessPlan(resp.data)

            const _addonsIds = resp.data?.addons.map((addon: any) => addon.addon_id).join(',');
            setAddonsIds(_addonsIds);
        } catch (error) {
            console.log('Error occurred while making the API request.');
            // Handle error
            setMyBusinessPlan([])
        }
    }


    const proposalDownload = (plan: any) => {
        return new Promise((resolve, reject) => {
            fetch(API_URL.ACCOUNT.MY_BUSINESS_PLAN_DOWNLOAD + "/" + plan.id, {
                headers: { Authorization: `Bearer ${tokenData}` }
            })
                .then(response => response.json())
                .then(resp => {
                    if (resp?.status == 200) {
                        resolve(resp.message);
                    } else {
                        reject(resp.message);
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error:', error);
                    reject(error.message);
                });
        })
    }


    useEffect(() => {
        getMyBusinessPlanDetails();
    }, [])


    const proposalDownloadHandler = async (plan: any) => {
        toast.promise(
            proposalDownload(plan), {
            loading: 'Checking...',
            success: (msg: any) => {
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    return (
        <>
            <Nav />
            <div className="body-part gray-bg mt-105">
                <div className="container text-center">
                    <h2 className="page-title">My <span>Business Plan Details</span></h2>
                    <p className="tag-line">View your Business Plan</p>

                    <div className="profile-box">
                        <div className="profile-box-title">
                            <div className="profile-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                                    <path d="M13 17C17.4183 17 21 13.4183 21 9C21 4.58172 17.4183 1 13 1C8.58172 1 5 4.58172 5 9C5 13.4183 8.58172 17 13 17Z" stroke="#D1D1D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1 24C3.42125 19.8162 7.81875 17 13 17C18.1813 17 22.5787 19.8162 25 24" stroke="#D1D1D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="profile-text">My Proposals Details</div>
                        </div>
                        <div className="text-left">
                            <div className=" ">

                                {myBusinessPlan?.answers.map((answer: any, index: number) => {
                                    let stepName = '';
                                    if (index === 0) {
                                        stepName = answer?.questionnaire_step?.title;
                                    } else {
                                        stepName = myBusinessPlan?.answers[index - 1]?.questionnaire_step?.title != answer?.questionnaire_step?.title ? answer?.questionnaire_step?.title : '';
                                    }

                                    return (
                                        <div key={index}>
                                            {stepName && <div className="my-3 font-serif text-2xl flex text-orange-400">
                                                <Icon name="price-tag" className="mr-3 mt-1" /> <b>{stepName}</b> </div>}

                                            <div className="pl-5">
                                                <div className="mt-3">   <b>{index + 1}. &nbsp;  {answer?.question?.question_text}</b> </div>

                                                <div className="flex mt-1">
                                                    <Icon name="answer" className="mr-2 pt-1 text-orange-400" size={16} />

                                                    {
                                                        (answer?.answer_text) ? answer?.answer_text :
                                                            ((answer?.options.length > 0) ?
                                                                answer?.options?.map(() => {
                                                                    return answer?.options?.map((_option: any, _i: number) => {
                                                                        return (_i > 0 ? ', ' : '') + _option?.option.option_text
                                                                    })
                                                                })
                                                                : '--')
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div>
                                    <div >
                                        {
                                            myBusinessPlan && myBusinessPlan.length === 0 && <p>No data found</p>
                                        }
                                        {
                                            !myBusinessPlan && <p>Loading...</p>
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className="btn-wrap mt-5">
                                <Link to={URLS.ACCOUNT.MY_BUSINESS_PLAN}><button className="update-btn">Back</button> </Link>
                                {
                                    myBusinessPlan?.status == 1 ?
                                        <button onClick={() => proposalDownloadHandler(myBusinessPlan)} className="update-btn">Download</button>
                                        :

                                        <Link to={replaceUrlParams(URLS.BUSINESS_PLAN, { subscriptionId: myBusinessPlan?.subscription_plan_id }) + "?addons=" + addonsIds + "&pId=" + proposalId}><button className="update-btn">Edit</button></Link>


                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>

    )
}

export default MyBusinessPlanDetails; 