import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL } from '../../_config'
import { CallApi, responseHandler } from '../api/callApi';
import { UpdateWithVariables } from '../../_common/functions';



export function* getMyBusinessPlan(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.ACCOUNT.MY_BUSINESS_PLAN, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



export function* updateProfile(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.ACCOUNT.MY_ACCOUNT_UPDATE, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.PUT, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            console.log('updateProfile===', respChecker)
            yield put({
                type: ACTIONS.ACCOUNT.MY_ACCOUNT_UPDATE,
                payload: {
                    user: respChecker.data?.data?.user,
                }
            })


            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}





