import { ActionExtended } from '../../_common/interfaces/ActionExtended';
import { User } from '../../_common/interfaces/models/user';
import { ACTIONS } from '../../_config'

export interface UserReducer {
  user: User | null;
  token: string | null;
  isAuthenticated: boolean;
  permissions: any;
}

const initialState: UserReducer = {
  user: null,
  token: null,
  isAuthenticated: false,
  permissions: null,
};

const userReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.AUTH.VERIFY_OTP:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token || localStorage.getItem('eh-ui-auth-key'),
        isAuthenticated: true,
      };

    case ACTIONS.ACCOUNT.MY_ACCOUNT_UPDATE:
      return {
        ...state,
        user: action.payload.user,
      };

    case ACTIONS.AUTH.LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false, // action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
