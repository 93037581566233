import { call, put } from 'redux-saga/effects';
import { API_URL } from '../../_config'
import { CallApi, responseHandler } from '../api/callApi';
import { UpdateWithVariables } from '../../_common/functions'; 



export function* getSubscriptionPlans(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.SUBSCRIPTION.PLAN_LIST, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data, true);
        if (resp.status == 200 && resp.data) {

            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }


    
}





