import React from "react";

interface BusinessPlanLeftTabProps {
    questionnaire: any; // replace 'any' with the appropriate type for 'questionnaire'
    currentActiveTab: any;
    handleTabClick: any
}

const BusinessPlanLeftTab = ({ questionnaire, currentActiveTab, handleTabClick }: BusinessPlanLeftTabProps) => {
    return (
        <>
            <div className="left-part-bp">
                {
                    questionnaire && questionnaire.length > 0 &&
                    questionnaire.map((item: any, index: number) => (
                        <div className={`left-menu ${(currentActiveTab == index) && 'selected'} ${(currentActiveTab > index) && 'done'}`} key={`left-menu_${index}`}
                            // onClick={() => handleTabClick(index)}
                        >
                            <span>{index + 1}</span>
                            <h3> {item?.title.split(" ").map((word: string, index: number) => (
                                index === 0 ? <span key={index}>{word} </span> : <strong key={index}>{word} </strong>
                            ))}</h3>
                        </div>
                    ))
                }
                {/* <div className="left-menu done" data-aos="fade-right">
                    <span>1</span>
                    <h3>Select <strong>Business Plan Objective</strong></h3>
                </div>
                <div className="left-menu selected" data-aos="fade-right">
                    <span>2</span>
                    <h3>Basic <strong>Business Information</strong></h3>
                </div>
                <div className="left-menu" data-aos="fade-right">
                    <span>3</span>
                    <h3>Customer <strong>Group Details</strong></h3>
                </div>
                <div className="left-menu" data-aos="fade-right">
                    <span>4</span>
                    <h3>Business <strong>Plan Basics</strong></h3>
                </div> */}
            </div>

        </>
    )
}

export default BusinessPlanLeftTab;