import axios from 'axios';
import { API_URL, STORAGE, URLS } from '../../_config';


const axiosApiInstance = axios.create();

// axiosApiInstance.interceptors.response.use((response) => {
// 	return response
// }, async function (error) {
// 	const originalRequest = error.config;
// 	if (error.response.status === 401 && !originalRequest._retry) {
// 		originalRequest._retry = true;
// 		const access_token = await refreshAccessToken();
// 		originalRequest.headers['Authorization'] = 'Bearer ' + access_token;
// 		return axiosApiInstance(originalRequest);
// 	}
// 	return Promise.reject(error);
// });



// const refreshAccessToken = async () => {
// 	let token = null;
// 	await axios.post(API_URL.USER.REFRESH_TOKEN, {
// 		accessToken: getToken(),
// 		refreshToken: getRefreshToken(),
// 	}).then((response) => {
// 		saveToken(response.data.data.accessToken, response.data.data.refreshToken);
// 		token = response.data.data.accessToken;
// 	}).catch((error2) => {
// 		localStorage.removeItem(STORAGE);
// 		(window as any).location.href(URLS.LOGIN)
// 	})
// 	return token;
// }

function saveToken(accessToken: any, refreshToken: any) {
	let token_str = localStorage.getItem(STORAGE)
	if (token_str) {
		let data = JSON.parse(token_str)
		data.accessToken = accessToken
		data.refreshToken = refreshToken;
		localStorage.setItem(STORAGE, JSON.stringify(data))
	}
}

function getToken() {
	let token_str = localStorage.getItem(STORAGE)
	if (token_str) {
		return JSON.parse(token_str).token
	} else {
		return null
	}
}


function getRefreshToken() {
	let token_str = localStorage.getItem(STORAGE)
	if (token_str) {
		return JSON.parse(token_str).refreshToken
	} else {
		return null
	}
}

const apiCatch = (error: any) => {
	if (error.response && error.response.status === 401 && window.location.pathname != URLS.LOGIN) {
		localStorage.removeItem(STORAGE)
		window.location.href = URLS.LOGIN
	}
}


export const responseHandler = (resp: any) => {
	if (resp.status >= 200 && resp.status < 300 && resp.data) {
		if (resp.data.data.exceptions?.length) {
			return { status: false, data: resp.data.data.exceptions.join(',') }
		} else {
			return { status: true, data: resp.data }
		}
	} else {
		return { status: false, data: resp.data }
	}
}

export class CallApi {
	static GET(url: any, params: any, isAuth: boolean = false, isAdaptive: any = (e: any) => { }) {
		return new Promise(async (resolve, reject) => {
			let urls = new URL(window?.location?.href);
			let headers: any = {

				'Content-Type': 'application/json',
				AppHost: urls.hostname
			}
			if (isAuth || isAdaptive) {
				let token = getToken()
				if (token) {
					headers.Authorization = `Bearer ${token}`
				}
			}

			return axiosApiInstance({ method: 'GET', url, headers, params }).then(success => {
				return resolve(success)
			}).catch(error => {
				apiCatch(error)
				return reject(error.response || error.message)
			})
		})
	}

	static POST(url: any, params: any, isAuth: boolean = false, isAdaptive: any = (e: any) => { }) {
		return new Promise((resolve, reject) => {
			const urls = new URL(window?.location?.href)
			let headers: any = {}
			const token = getToken()
			if (isAuth || token) {
				const token = getToken()
				if (token) {
					headers.Authorization = `Bearer ${token}`
					// headers.AppHost = urls.hostname
				}
			} else {
				headers.AppHost = urls.hostname
			}

			return axiosApiInstance.post(url, params, { headers: headers, onUploadProgress: isAdaptive }).then(success => {
				return resolve(success)
			}).catch(error => {
				apiCatch(error)
				return reject(error.response || error.message)
			})
		})
	}

	static PUT(url: string, params: any, isAuth: boolean = false, isAdaptive: any = (e: any) => { }) {
		return new Promise((resolve, reject) => {
			const urls = new URL(window?.location?.href)
			let headers: any = { 'Content-Type': 'application/json' }
			if (isAuth || isAdaptive) {
				const token = getToken()
				if (token) {
					headers.Authorization = `Bearer ${token}`
					// headers.AppHost = urls.hostname
				}
			} else {
				headers.AppHost = urls.hostname
			}
			return axiosApiInstance.put(url, params, { headers: headers }).then(success => {
				return resolve(success)
			}).catch(error => {
				apiCatch(error)
				return reject(error.response || error.message)
			})

			 
		})
	}

	static DELETE(url: string, params: any, isAuth: boolean = false, isAdaptive: any = (e: any) => { }) {
		return new Promise((resolve, reject) => {
			const urls = new URL(window?.location?.href)
			let headers: any = { 'Content-Type': 'application/json' }
			const token = getToken()
			if (isAuth || token) {
				if (token) {
					headers.Authorization = `Bearer ${token}`
					// headers.AppHost = urls.hostname
				}
			} else {
				headers.AppHost = urls.hostname
			}

			return axiosApiInstance.delete(url, { headers: headers, onUploadProgress: isAdaptive, data: params }).then(success => {
				return resolve(success)
			}).catch(error => {
				apiCatch(error)
				return reject(error.response || error.message)
			})
			/* return axiosApiInstance({ method: 'DELETE', url, headers, params, }).then(success => {
				return resolve(success)
			}).catch(error => {
				apiCatch(error)
				return reject(error.response)
			}) */
		})
	}
}
