import { Link } from "react-router-dom"
import { API_URL, URLS } from "../_config"
import Footer from "../components/layouts/footer"
import Nav from "../components/layouts/nav"
import { useEffect, useState } from "react"

const Home = () => {
  const [subscriptionPlanType, setSubscriptionPlanType] = useState<any>(null);


  const getSubscriptionPlanType = async () => {
    try {
      const response = await fetch(API_URL.SUBSCRIPTION.PLAN_TYPE_LIST);
      const resp = await response.json();
      setSubscriptionPlanType(resp.data)
    } catch (error) {
      setSubscriptionPlanType([])
    }
  };

  useEffect(() => {
    getSubscriptionPlanType();
  }, [])

  return (
    <>
      <Nav />
      <div className="banner mt-105">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="banner-text">
                <span data-aos="fade-up">Welcome to our</span>
                <h1 data-aos="fade-up">business plan site</h1>
                <p data-aos="fade-up">We help you create professional business plans tailored to your needs. Start by selecting a business plan type.</p>
                <Link to={URLS.SUBSCRIPTION} data-aos="zoom-in"><button type="button" className="master-orng-btn xl-orng-btn">Start Your Business Plan</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="body-part mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="sub-heading pt-120">
                <h2 data-aos="fade-up">Select your<br /> Business Plan Type</h2>
                <span className="line-org" data-aos="fade-up"></span>
                <Link to={URLS.SUBSCRIPTION} data-aos="fade-up"><button type="button" className="master-orng-btn mt-40">Start Your Business Plan</button></Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="inner-all-boxes">
                {
                  subscriptionPlanType && subscriptionPlanType.map((plan: any, index: number) => (
                    <div className={`inner-boxes t${index + 1}`} key={index} data-aos="fade-up">
                      <div className="inner-boxes-img">
                        {/* <img src={plan.image} alt="#" /> */}
                        {
                          index === 0 ? <img src="../../../images/img/c2.svg" alt="#" />
                          : <img src="../../../images/img/c1.svg" alt="#" />
                        }
                        
                        </div>
                      <h3>{plan.title}</h3>
                      <div dangerouslySetInnerHTML={{ __html: plan.pitch }} />

                      <Link to={URLS.SUBSCRIPTION + "?id=" + plan.id} data-aos="fade-up">
                        <img src="../../../images/img/down-arrow.svg" alt="#" className="down-in-arrow" />
                      </Link>
                    </div>
                  ))
                }
                {/* <div className="inner-boxes t1 d-none" data-aos="fade-up">
                  <div className="inner-boxes-img"><img src="../../../images/img/c1.svg" alt="#" /></div>
                  <h3>Commercial Business Plans</h3> 
                  <img src="../../../images/img/down-arrow.svg" alt="#" className="down-in-arrow" />
                </div>
                <div className="inner-boxes t2 d-none" data-aos="fade-up">
                  <div className="inner-boxes-img"><img src="../../../images/img/c2.svg" alt="#" /></div>
                  <h3>Immigration Business Plans</h3> 
                  <img src="../../../images/img/down-arrow.svg" alt="#" className="down-in-arrow" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="body-part gray-bg">
        <div className="container">
          <div className="sub-heading text-center mb-50">
            <h2 data-aos="fade-up">How it Works?</h2>
            <p data-aos="fade-up">Our AI business plan generator guides you through a few questions, once done a<br />business plan example will be generated</p>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="work-box" data-aos="fade-right" data-aos-duration="2000">
                <img src="../../../images/img/h1.svg" alt="#" />
                <h3>Enter General Business Information</h3>
                <p>Only basic business information is required e.g. business name, number of employees, and a little financial data</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="work-box" data-aos="fade-left" data-aos-duration="2000">
                <img src="../../../images/img/h2.svg" alt="#" />
                <h3>Easily Editable and Save the plan</h3>
                <p>Once your AI plan is generated you can easily edit the plan with our "Talk To Plan" feature</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="body-part mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="faq-img" data-aos="zoom-in"><img src="../../../images/img/question-img.png" alt="#" /></div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="sub-heading mb-50 text-start">
                <span data-aos="fade-up">YOU ASK. WE ANSWER</span>
                <h2 data-aos="fade-up">Frequently Asked<br />Questions</h2>
              </div>
              <div className="accordion faq-accordian" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Accordion Item #1
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance,
                      as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>,
                      though the transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Accordion Item #2
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance,
                      as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>,
                      though the transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Accordion Item #3
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance,
                      as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>,
                      though the transition does limit overflow.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>

  )
}

export default Home
