import { Link } from "react-router-dom"
import { URLS } from "../../_config"

const PaymentMethod = () => {
    return (
        <>
            <div className="login-bg"></div>

            <div className="payment-box">
                <Link to={URLS.SUBSCRIPTION} className="cancel-btn"><img src="./images/img/cancel-arrow.png" alt=""/></Link>
                <div className="payment-left-box">
                    <div className="env-box mb-20">
                        <span><i className='bx bx-envelope'></i> codingnepalweb@gmail.com</span>
                        <i className='bx bxs-check-circle'></i>
                    </div>
                    <div className="accordion payment-method" id="accordionExample">
                        <h4>Choose payment method</h4>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                     
                                    <div className="radio-wrap">
                                        <label className="container-radio">Stripe Payment
                                            <input type="radio" checked={true} name="radio" wfd-id="id8"/>
                                                <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <img src="./images/img/strip.png" alt=""/>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-3 float-input light-float">
                                                    <label className="form-label">Name on card <span>*</span></label>
                                                    <input type="email" className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-3 float-input light-float">
                                                    <label className="form-label">Card Number <span>*</span></label>
                                                    <input type="email" className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="mb-3 float-input light-float">
                                                    <label className="form-label">Expiry date <span>*</span></label>
                                                    <input type="email" className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="mb-3 float-input light-float">
                                                    <label className="form-label">CVC/CVV</label>
                                                    <input type="email" className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-1">
                                                <div className="encripted-text">
                                                    <span><i className='bx bx-lock-alt'></i> Your transaction is secured with SSL encryption</span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <div className="radio-wrap">
                                        <label className="container-radio">Paypal
                                            <input type="radio" name="radio" wfd-id="id8"/>
                                                <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <img src="./images/img/paypal.png" alt=""/>
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-3 float-input light-float">
                                                    <label className="form-label">Name on card <span>*</span></label>
                                                    <input type="email" className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-3 float-input light-float">
                                                    <label className="form-label">Card Number <span>*</span></label>
                                                    <input type="email" className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="mb-3 float-input light-float">
                                                    <label className="form-label">Expiry date <span>*</span></label>
                                                    <input type="email" className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="mb-3 float-input light-float">
                                                    <label className="form-label">CVC/CVV</label>
                                                    <input type="email" className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-1">
                                                <div className="encripted-text">
                                                    <span><i className='bx bx-lock-alt'></i> Your transaction is secured with SSL encryption</span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-4">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <div className="radio-wrap">
                                        <label className="container-radio">Credit or debit Card
                                            <input type="radio" checked={true} name="radio" wfd-id="id8"/>
                                                <span className="checkmark"></span>
                                        </label>
                                    </div>

                                    <img src="./images/img/cards.png" alt=""/>
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-3 float-input light-float">
                                                    <label className="form-label">Name on card <span>*</span></label>
                                                    <input type="email" className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-3 float-input light-float">
                                                    <label className="form-label">Card Number <span>*</span></label>
                                                    <input type="email" className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="mb-3 float-input light-float">
                                                    <label className="form-label">Expiry date <span>*</span></label>
                                                    <input type="email" className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="mb-3 float-input light-float">
                                                    <label className="form-label">CVC/CVV</label>
                                                    <input type="email" className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-1">
                                                <div className="encripted-text">
                                                    <span><i className='bx bx-lock-alt'></i> Your transaction is secured with SSL encryption</span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="float-input light-float">

                            <label className="form-label">Country or region</label>
                            <select className="form-select form-control orang-drop" aria-label="Default select example">
                                <option selected>India</option>
                                <option value="1">USA</option>
                                <option value="2">UK</option>
                                <option value="3">RUS</option>
                            </select>
                        </div>


                    </div>
                </div>

                <div className="payment-right-box">
                    <div className="country-select-box">
                        <select className="form-select" aria-label="Default select example">
                            <option value="en">IND</option>
                            <option value="1">USA</option>
                            <option value="2">UK</option>
                            <option value="3">RUS</option>
                        </select>
                        <div className="phone-info">
                            <input type="text" placeholder="Phone Number" />
                            <img src="./images/img/info-icon.svg" alt=""/>
                        </div>
                    </div>

                    <div className="summery-table mt-4">
                        <h3>Summary</h3>
                        <table>
                            <tbody>
                                <tr className="bb-1">
                                    <td>
                                        <h4>Pro (Billed monthly)</h4>
                                        <h5>Save 20% with annual billing</h5>
                                    </td>
                                    <td className="text-end"><span className="price-money">$7.84 <sub>/mo</sub></span></td>
                                </tr>
                                <tr>
                                    <td><span className="table-body-txt">Subtotal</span></td>
                                    <td className="text-end"><span className="table-body-txt">$148.00</span></td>
                                </tr>
                                <tr className="bb-1">
                                    <td><span className="table-body-txt">VAT 20%</span></td>
                                    <td className="text-end"><span className="table-body-txt">$29.60</span></td>
                                </tr>
                                <tr className="bb-1">
                                    <td><span className="table-body-txt"><b>Total Cost</b></span></td>
                                    <td className="text-end"><span className="table-body-txt">$177.60</span></td>
                                </tr>

                            </tbody>
                        </table>
                        <Link to={URLS.LOGIN}>
                            <button type="button" className="master-orng-btn mt-4 w-100">Pay</button>
                        </Link>
                        <p>Powered by <img src="./images/img/strip.svg" alt=""/></p>
                    </div>
                    <div className="summery-logo">
                        <img src="./images/img/logo.png" alt=""/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentMethod