import { takeLatest, all, takeEvery } from 'redux-saga/effects'; // fork, take,  takeEvery, takeLeading
import { SAGA_ACTIONS } from '../../_config';
 

import * as subscriptionSaga from './subscriptionSaga';
import * as authSaga from './authSaga';
import * as myAccountSaga from './myAccountSaga';


export function* sagas() {
  yield all([
    takeLatest(SAGA_ACTIONS.SUBSCRIPTION.PLAN_LIST, subscriptionSaga.getSubscriptionPlans), 

    takeLatest(SAGA_ACTIONS.AUTH.VERIFY_OTP, authSaga.verifyOtpAndLogin), 
    takeLatest(SAGA_ACTIONS.AUTH.LOGOUT, authSaga.logout), 


    takeLatest(SAGA_ACTIONS.ACCOUNT.MY_ACCOUNT_UPDATE, myAccountSaga.updateProfile), 
    takeLatest(SAGA_ACTIONS.ACCOUNT.MY_BUSINESS_PLAN, myAccountSaga.getMyBusinessPlan), 

  ]);
}