import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home'; 
import Login from './pages/auth/Login';
import Otp from './pages/auth/Otp';
import { URLS } from './_config';
import Subscription from './pages/auth/Subscription';
import AddOnsForm from './pages/auth/AddOnsForm';
import BusinessPlan from './pages/business_plan/BusinessPlan';
import PaymentMethod from './pages/payment_method/PaymentMethod';
import AboutUs from './pages/cms_pages/AboutUs';
import ContactUs from './pages/cms_pages/Contactus';
import MyBusinessPlan from './pages/user/MyBusinessPlan';
import MyAccount from './pages/user/MyAccount';
import Logout from './pages/auth/Logout';
import MyBusinessPlanDetails from './pages/user/MyBusinessPlanDetails';
import ThankYou from './pages/business_plan/ThankYou';
import EmailLogin from './pages/auth/EmailLogin';

function App() {
  return (
    <BrowserRouter >
      <div  >
        <Routes>
          <Route path={URLS.HOME} element={<Home />} />
          <Route path={URLS.LOGIN} element={<Login />} />
          <Route path={URLS.EMAIL_LOGIN} element={<EmailLogin />} />
          <Route path={URLS.OTP} element={<Otp />} />
          <Route path={URLS.LOGOUT} element={<Logout />} />

          <Route path={URLS.SUBSCRIPTION} element={<Subscription />} />
          <Route path={URLS.ADDONS} element={<AddOnsForm />} />
          <Route path={URLS.BUSINESS_PLAN} element={<BusinessPlan />} />
          <Route path={URLS.THANKYOU_PAGE} element={<ThankYou />} />
          <Route path={URLS.PAYMENT_METHOD} element={<PaymentMethod />} />


          <Route path={URLS.ACCOUNT.MY_ACCOUNT} element={<MyAccount />} />
          <Route path={URLS.ACCOUNT.MY_BUSINESS_PLAN} element={<MyBusinessPlan />} />
          <Route path={URLS.ACCOUNT.MY_BUSINESS_PLAN_DETAILS} element={<MyBusinessPlanDetails />} />


          
          <Route path={URLS.CMS_PAGES.ABOUT_US} element={<AboutUs />} />
          <Route path={URLS.CMS_PAGES.CONTACT_US} element={<ContactUs />} /> 
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
