import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi, responseHandler } from '../api/callApi';
import { UpdateWithVariables } from '../../_common/functions';


export function* verifyOtpAndLogin(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.AUTH.VERIFY_OTP, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            localStorage.setItem(STORAGE, JSON.stringify(resp.data.data));

            console.log('respChecker', respChecker)
            yield put({
                type: ACTIONS.AUTH.VERIFY_OTP,
                payload: { 
                    user: respChecker.data?.data?.customer?.user,
                    token: respChecker.data?.data?.token, 
                }
            })

            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* logout(action: any): any {
    try {
        localStorage.removeItem(STORAGE)
        // sessionStorage.removeItem(UPDATED_USER)
        yield put({
            type: ACTIONS.AUTH.LOGOUT,
        });
        // yield put({
        //     type: ACTIONS.RESET_REDUCER,
        // });
        action && action.callbackSuccess && action.callbackSuccess({});
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCustomerDetails(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.ACCOUNT.MY_ACCOUNT, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
        if (respChecker.status) {
            console.log('respChecker==user', respChecker)
            yield put({
                type: ACTIONS.ACCOUNT.MY_ACCOUNT,
                payload: { 
                    user: respChecker.data?.data?.user, 
                }
            })


            action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
        } else {
            action && action.callbackError && action.callbackError(respChecker.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}





