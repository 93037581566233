import React from 'react';
import { URLS } from '../../_config';
import { Link } from 'react-router-dom';

const ThankYou: React.FC = () => {
    return (

        <div className="thank-you-page">
            <div className="home-icon"><Link to={URLS.HOME}><i className='bx bx-home'></i></Link></div>
            <div className="thank-you-text">
                <img src="/images/img/thank-you-img.png" alt='' />
                <h1>Thank you <span>for <b>Contacting</b> Us!</span></h1>
                <p>We have received your message, We'll reach you out immediately!</p>
                <span data-aos="zoom-in" className="aos-init aos-animate">
                    <button type="button" className="master-orng-btn xl-orng-btn">Click on Email Link</button>
                </span>
            </div>
            <div className="connect-sec">
                <div className="cont-txt"><h4>Let's Connect! :</h4><span className="line-gr"></span></div>
                <div className="cont-sosial-links">
                    <ul>
                        <li><a href="#"><i className='bx bxl-facebook' ></i></a></li>
                        <li><a href="#"><i className='bx bxl-instagram-alt' ></i></a></li>
                        <li><a href="#"><i className='bx bxl-linkedin-square' ></i></a></li>
                        <li><a href="#"><i className='bx bxl-youtube' ></i></a></li>
                        <li><a href="#"><i className='bx bxl-twitter' ></i></a></li>
                        <li><a href="#"><i className='bx bxl-pinterest' ></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ThankYou;