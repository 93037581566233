import { API_BASE_URL } from "./site_urls"
export const STORAGE = "plan-crafter"
export const STORAGE_VERSION = "202408131509"

export const URLS = {
    HOME: "/",
    LOGIN: "/login",
    EMAIL_LOGIN: "/email-login",
    OTP: "/otp",
    LOGOUT: "/logout",
    SUBSCRIPTION: "/subscription",
    ADDONS: "/addons/:subscriptionId",

    BUSINESS_PLAN: "/business-plan/:subscriptionId",
    PAYMENT_METHOD: "/payment-method",
    THANKYOU_PAGE: "/thank-you",

    ACCOUNT: {
        MY_ACCOUNT: "/account/profile",
        MY_BUSINESS_PLAN: "/account/my-business-plan",
        MY_BUSINESS_PLAN_DETAILS: "/account/my-business-plan-details/:proposalId",
    },

    CMS_PAGES: {
        ABOUT_US: "/about-us",
        CONTACT_US: "/contact-us"
    }
}


export const API_URL = {
    SUBSCRIPTION: {
        // PLAN_LIST: `https://dummy.restapiexample.com/api/v1/employees`,
        PLAN_TYPE_LIST: `${API_BASE_URL}/subscription-plan-types`,  // For Home page
        PLAN_LIST: `${API_BASE_URL}/subscription-plans`,
    },

    ADD_ONS: {
        LIST: `${API_BASE_URL}/addons`,
    },

    BUSINESS_PLAN: {
        QUESTIONNAIRE: `${API_BASE_URL}/questionnaire`,
        PROPOSAL_CREATE: `${API_BASE_URL}/proposal/create`,
        PROPOSAL_UPDATE: `${API_BASE_URL}/proposal/update`,
    },

    AUTH: {
        LOGIN: `${API_BASE_URL}/customer/login`,
        VERIFY_OTP: `${API_BASE_URL}/customer/verify-otp`,
        RESEND_OTP: `${API_BASE_URL}/customer/resend-otp`,
    },

    ACCOUNT: {
        MY_ACCOUNT: `${API_BASE_URL}/users`,
        MY_ACCOUNT_UPDATE: `${API_BASE_URL}/users/update`,
        MY_AVATAR_UPDATE: `${API_BASE_URL}/users/avatar`,

        MY_BUSINESS_PLAN: `${API_BASE_URL}/customer/proposals`,
        MY_BUSINESS_PLAN_DOWNLOAD: `${API_BASE_URL}/proposal/download`,  // /:proposalId
        MY_BUSINESS_PLAN_DETAILS: `${API_BASE_URL}/proposal/details`,  // /:proposalId
    },

}


export const ACTIONS = {
    RESET_REDUCER: "ACTIONS/RESET_REDUCER",

    LOADER: {
        SET_FP_STATUS: "ACTIONS/LOADER/SET_FP_STATUS",
        SET_SIDEBAR: "ACTIONS/LOADER/SET_SIDEBAR"
    },

    AUTH: {
        VERIFY_OTP: "ACTIONS/AUTH/VERIFY_OTP",
        LOGOUT: "ACTIONS/AUTH/LOGOUT",
    },
    ACCOUNT: {
        MY_ACCOUNT: "ACTIONS/AUTH/MY_ACCOUNT",
        MY_ACCOUNT_UPDATE: "ACTIONS/AUTH/MY_ACCOUNT_UPDATE",
    },

}

export const SAGA_ACTIONS = {
    SUBSCRIPTION: {
        PLAN_LIST: "SAGA_ACTIONS/SUBSCRIPTION/PLAN_LIST",
    },

    AUTH: {
        VERIFY_OTP: "SAGA_ACTIONS/AUTH/VERIFY_OTP",
        LOGOUT: "SAGA_ACTIONS/AUTH/LOGOUT",
    },

    ACCOUNT: {
        MY_ACCOUNT: "SAGA_ACTIONS/ACCOUNT/MY_ACCOUNT",
        MY_ACCOUNT_UPDATE: "SAGA_ACTIONS/ACCOUNT/MY_ACCOUNT_UPDATE",
        MY_BUSINESS_PLAN: "SAGA_ACTIONS/ACCOUNT/MY_BUSINESS_PLAN",
    },
}