import { useEffect, useState } from "react";
import Footer from "../../components/layouts/footer";
import { API_URL, URLS } from "../../_config";
import { useToken } from "../../_common/hooks/authHook";
import { replaceUrlParams, showDate } from "../../_common/functions";
import toast from "react-hot-toast";
import Nav from "../../components/layouts/nav";
import { Link } from "react-router-dom";
import Icon from "../../components/icon";

const MyBusinessPlan = () => {
    const [myBusinessPlan, setMyBusinessPlan] = useState<any>(null);
    let tokenData: any = useToken();

    const getMyBusinessPlan = async () => {
        try {
            const response = await fetch(API_URL.ACCOUNT.MY_BUSINESS_PLAN, {
                headers: { Authorization: `Bearer ${tokenData}` }
            });
            const resp = await response.json();
            console.log(resp.data);
            setMyBusinessPlan(resp.data)
        } catch (error) {
            console.log('Error occurred while making the API request.');
            // Handle error
            setMyBusinessPlan([])
        }
    }


    const proposalDownload = (plan: any) => {
        return new Promise((resolve, reject) => {
            fetch(API_URL.ACCOUNT.MY_BUSINESS_PLAN_DOWNLOAD + "/" + plan.id, {
                headers: { Authorization: `Bearer ${tokenData}` }
            })
                .then(response => response.json())
                .then(resp => {
                    if (resp?.status == 200) {
                        window.open(resp.data.pdf, '_blank'); // Open URL in a new tab
                        resolve(resp.message);
                    } else {
                        reject(resp.message);
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error:', error);
                    reject(error.message);
                });
        })
    }

    const proposalDownloadHandler = async (plan: any) => {
        toast.promise(
            proposalDownload(plan), {
            loading: 'Checking...',
            success: (msg: any) => {
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    useEffect(() => {
        getMyBusinessPlan();
    }, [])


    return (
        <>
            <Nav />
            <div className="body-part gray-bg mt-105">
                <div className="container text-center">
                    <h2 className="page-title">My <span>Business Plan</span></h2>
                    <p className="tag-line">Manage your Business Plan</p>

                    <div className="profile-box">
                        <div className="profile-box-title">
                            <div className="profile-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                                    <path d="M13 17C17.4183 17 21 13.4183 21 9C21 4.58172 17.4183 1 13 1C8.58172 1 5 4.58172 5 9C5 13.4183 8.58172 17 13 17Z" stroke="#D1D1D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1 24C3.42125 19.8162 7.81875 17 13 17C18.1813 17 22.5787 19.8162 25 24" stroke="#D1D1D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="profile-text">My Proposals</div>
                        </div>
                        <div className="profile-box-data">
                            <div className="table-responsive">
                                <table className="table table-striped plan-craft-table">
                                    <thead>
                                        <tr>
                                            <th>Submitted date</th>
                                            <th>Business Plan</th>
                                            <th>Proposal Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {myBusinessPlan && myBusinessPlan.map((plan: any, index: number) => {
                                            const addonsIds = plan?.addons.map((addon: any) => addon.addon_id).join(',');
                                            return (
                                                <tr key={index}>
                                                    <td>{showDate(plan.created_at)}</td>
                                                    <td>
                                                        {plan?.subscription_plan?.subscription_plan_type?.title}
                                                        - <strong>{plan.subscription_plan?.title}</strong>
                                                    </td>
                                                    <td className="status success"><span>{(plan.status) ? 'Completed' : 'Pending'}</span></td>
                                                    <td className="actions">
                                                        <span title="View Proposal"><Link to={replaceUrlParams(URLS.ACCOUNT.MY_BUSINESS_PLAN_DETAILS, { proposalId: plan.id })}><img src="/images/img/view-icon.svg" alt="" /></Link></span>
                                                        {
                                                            plan.status == 0 ?
                                                                <span title="Edit Proposal">
                                                                    <Link to={replaceUrlParams(URLS.BUSINESS_PLAN, { subscriptionId: plan.subscription_plan_id }) + "?addons=" + addonsIds + "&pId=" + plan.id}>
                                                                        <img src="/images/img/pencil.png" alt="" />
                                                                    </Link>
                                                                </span>
                                                                :

                                                                plan?.pdf_file ? <span onClick={() => proposalDownloadHandler(plan)} title={`${plan?.pdf_file ? "Download Proposal" : "Not ready to download"}`}>
                                                                    <img src="/images/img/download-icon.svg" alt=""  />
                                                                </span> : <span title="Not ready to download"><Icon name="info" className="text-red-500" /></span>
                                                        }

                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td colSpan={4}>
                                                {
                                                    myBusinessPlan && myBusinessPlan.length === 0 && <p>No data found</p>
                                                }
                                                {
                                                    !myBusinessPlan && <p>Loading...</p>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>

    )
}

export default MyBusinessPlan; 