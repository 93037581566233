import React from 'react';
import Nav from '../../components/layouts/nav';
import Footer from '../../components/layouts/footer';

const ContactUs: React.FC = () => {
    return (
        <>
            <Nav />
            <div className="banner mt-105">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="banner-text">
                                <span data-aos="fade-up">Welcome to our</span>
                                <h1 data-aos="fade-up">Contact Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ContactUs;