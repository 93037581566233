import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../_config";
import { useAuthApi } from "../../_common/hooks/api/authApiHook";

const Logout = () => {
    const authApi = useAuthApi(); 
    const navigate = useNavigate();

    const logout = () => {
        authApi.logout({}, (msg: any, resp: any) => {
            navigate(URLS.HOME);
        }, (msg: any) => { });
    }
    useEffect(() => {
        logout();
    }, [])

    return (
        <React.Fragment>

        </React.Fragment>
    )
}

export default Logout;