import moment from "moment"

export const UpdateWithVariables = (url: string, data: any) => {
    if (data) {
        let dynamicParams = url.match(/:[A-Z_]*/gi)
        dynamicParams?.map(key => {
            if (key.length > 1) url = url.replace(key, data[key.replace(':', '')])
        })
    }
    return url
}


export const replaceUrlParams = (url: string, params: any) => {
    Object.keys(params).map((param) => {
        url = url.replace(`:${param}`, params[param])
    })
    return url
}


export const showDate = (dbDate: any, dateFormat: any = 'DD MMM, YYYY') => {
    return (dbDate !== '') ? moment(dbDate).format(dateFormat) : "";
}
