import React, { useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { API_URL, URLS } from "../../_config"
import toast from "react-hot-toast";
import Error from "../../components/formFieldError";
import { Controller, useForm } from "react-hook-form";
import { useToken } from "../../_common/hooks/authHook";

const EmailLogin = () => {
    const navigate: any = useNavigate();
    const { control, formState: { errors }, handleSubmit, setError } = useForm();

    const urlParams: any = useParams();
    const _pid = urlParams?.pid;
    const _token = urlParams?.token;
 

    let tokenData: any = useToken();


    const submitLogin = (params: any) => {
        return new Promise((resolve, reject) => { 
            fetch(API_URL.AUTH.LOGIN, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(params),
            })
                .then(response => response.json())
                .then(resp => {
                    if (resp?.status == 200) {
                        // Handle success
                        console.log('Response:', resp); 
                        resolve(resp.message);
                    } else {
                        reject(resp.message);
                        const errorResp = resp?.data;
                        if (errorResp) {
                            Object.keys(errorResp).map((key) => {
                                setError(key, {
                                    type: "manual",
                                    message: errorResp[key]
                                })
                            })
                        }
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error:', error);
                    reject(error.message);
                });
        })
    }


    const saveFormHandler = async (data: any) => {  
        toast.promise(
            submitLogin(data), {
            loading: 'Sending Email...',
            success: (msg: any) => { 
                localStorage.setItem("loginEmail", data.email);
                navigate(URLS.OTP); 

                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    const getFieldValidations = (fieldName: string) => { 
        let returnValidation: any = {};
        if (fieldName === 'email') {
            returnValidation.required = "The email field is required."
            returnValidation.pattern = { value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, message: "Invalid email address." }
        }  
        return returnValidation
    }

    useEffect(() => {
        if (_pid && _token) {
            // Logic to verify the email
            // For example, you can use the history object from react-router-dom
            // to push the next page to the navigation stack
            console.log(_pid, _token)
        }
    })

    return (
        <React.Fragment>
            <div className="login-bg"></div>
            <div className="login-box">
                <div className="login-box-left">
                    <Link to={URLS.HOME} className="logo-login"><img src="./images/img/logo.png" alt="" /></Link>
                    <div className="login-text" data-aos="fade-up">
                        <h2>Hey There!</h2>
                        <p>Welcome Back.</p>
                        <p>You are just one step away to your feed</p>
                    </div>
                </div>
                <div className="login-box-right">
                    <h2>Log In to <span>PlanCrafter</span></h2>
                    <p>Enter your login details please</p>
                    <div data-aos="fade-up" className="form-wrap">
                        <form onSubmit={handleSubmit(saveFormHandler)} >
                            <div className="mb-3 float-input">
                                <label className="form-label">Email <span className="text-danger">*</span></label> 
                                <Controller
                                    defaultValue={""}
                                    control={control}
                                    name={`email`}
                                    rules={getFieldValidations(`email`)}
                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                        <input type="text" name={name} onChange={onChange} value={value} className="form-control" autoComplete="off" placeholder="name@example.com" spellCheck="false" data-ms-editor="true" />
                                    )}
                                />
                                <Error error={errors[`email`]} customClass="text-xs" />
                            </div>
                            <button type="submit" className="primary-btn-plan-craft w-100">Sign In</button>
                        </form>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default EmailLogin