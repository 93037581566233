import { Link, useParams } from "react-router-dom"; 
import Footer from "../../components/layouts/footer";
import Nav from "../../components/layouts/nav";
import { API_URL, URLS } from "../../_config";
import { useEffect, useState } from "react"; 
import { useNavigate } from "react-router-dom";
import { replaceUrlParams } from "../../_common/functions"; 

const AddOnsForm = () => {
    const [addOns, setAddOns] = useState<any>(null);
    const [selectedAddOns, setSelectedAddOns] = useState<any>([]);
    const navigate = useNavigate();

    const urlParams: any = useParams();
    const subscriptionId = urlParams?.subscriptionId;
 

    const getAddons = async () => {
        try {
            const response = await fetch(API_URL.ADD_ONS.LIST);
            const resp = await response.json();
            console.log(resp.data);
            setAddOns(resp.data)
        } catch (error) {
            console.log('Error occurred while making the API request.');
            // Handle error
            setAddOns([])
        }
    }

    useEffect(() => {
        getAddons();
    }, [])


    const selectAddOnsHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedId = parseInt(event.target.value);
        if (event.target.checked) {
            setSelectedAddOns((prevSelectedAddOns:any) => [...prevSelectedAddOns, selectedId]);
        } else {
            setSelectedAddOns((prevSelectedAddOns:any) => prevSelectedAddOns.filter((id: number) => id != selectedId));
        }
    }
    console.log(selectedAddOns);

    
    const goAddOnsPageHandler = () => {
        // Logic to navigate to the next page
        // For example, you can use the history object from react-router-dom
        // to push the next page to the navigation stack
        console.log(URLS.BUSINESS_PLAN)
        navigate(replaceUrlParams(URLS.BUSINESS_PLAN, { subscriptionId: subscriptionId }) + "?addons=" + selectedAddOns);
    }

    return (
        <>
            <Nav />
            <div className="body-part gray-bg mt-105">
                <div className="container-fluid">
                    <h2 className="page-title text-center" data-aos="fade-down" data-aos-duration="1000"><span>You may also</span> like to include</h2>
                    <p className="tag-line text-center mb-5" data-aos="fade-down" data-aos-duration="1000">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical</p>

                    <div className="managementplan_ctrl">
                        <div className="owl-carousel mb-5">

                            {addOns && addOns.map((addOn: any, index: number) => (
                                <div key={`addOn_${addOn.id}`}>
                                    <div className="brand_management_plan">
                                        <div className="brand_managementtitle">
                                            <h5>{addOn.title}</h5>
                                            <div className="form-check form-switch ms-auto">
                                                <input className="form-check-input" type="checkbox" value={addOn.id}
                                                onChange={selectAddOnsHandler}
                                                    role="switch" id={`flexSwitchCheckDefault${index}`} />
                                            </div>
                                        </div>
                                        <div className="barnd_managementcontent">
                                            <h4>Description:</h4>
                                            <p dangerouslySetInnerHTML={{ __html: addOn?.description }}></p>
                                        </div>
                                        <div className="barnd_managementcontent">
                                            <h4>Features:</h4>
                                            <ul>
                                                {addOn?.features && addOn?.features?.map((feature: any, i: number) => (
                                                    <li key={i}>{feature?.title}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="btn-wrap-bp btn-center">
                            <Link to={URLS.SUBSCRIPTION}><button className="outline-btn">Back</button></Link>
                            <button onClick={() => goAddOnsPageHandler()}><button className="solid-btn">Next</button></button>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default AddOnsForm;