import axios from "axios";
import { API_URL, SAGA_ACTIONS } from "../../../_config";
import { UpdateWithVariables } from "../../functions";
import { CallApi } from "../../../store/api/callApi";
import { useApiCall } from "../common/appApiCallHook";

export function useAuthApi() {
  const callApi = useApiCall();


  const verifyOtpAndLogin = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.AUTH.VERIFY_OTP, data, onSuccess, onError);
  };


  const logout = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.AUTH.LOGOUT, data, onSuccess, onError);
  };


  return {
    verifyOtpAndLogin, logout
  };
}