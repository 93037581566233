import { useSelector } from "react-redux"
import { StateExtended } from "../interfaces/StateExtended"

export function useUser() {
    const user = useSelector((state: StateExtended) => state.user)
    return user.user
}

export function useToken() {
    const user = useSelector((state: StateExtended) => state.user)
    return user.token
}
