import createSagaMiddleware from 'redux-saga'
import { applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer  from './reducers'
import { sagas } from './sagas'
import { configureStore } from '@reduxjs/toolkit'

const middlewares:any = []

const sagaMiddleware = createSagaMiddleware()
middlewares.push(sagaMiddleware)

let middleware:any = applyMiddleware(...middlewares)

//if (['dev'].indexOf(import.meta.env.VITE_APP_STAGE) >= 0) {
if (['dev'].indexOf('dev') >= 0) {
  if ((window as any).devToolsExtension) {
    middleware = compose(middleware, (window as any).devToolsExtension())
  }
  if ((window as any).__REDUX_DEVTOOLS_EXTENSION__) {
    middleware = compose(
      middleware,
      (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
    )
  }
}



const persistConfig = {
  key: 'root',
  storage,
  blacklist: [],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// const store = createStore<any & PersistPartial, any, any, any>(persistedReducer, middleware);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
})

const persistor = persistStore(store)

sagaMiddleware.run(sagas)

export { store, persistor }