import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-12">
                            <div className="footer-logo">
                                <Link to="#"><img src="../../../images/img/logo.png" alt="#" /></Link>
                                <ul>
                                    <li><Link to="#"><i className='bx bxl-facebook'></i></Link></li>
                                    <li><Link to="#"><i className='bx bxl-instagram-alt' ></i></Link></li>
                                    <li><Link to="#"><i className='bx bxl-linkedin-square' ></i></Link></li>
                                    <li><Link to="#"><i className='bx bxl-youtube' ></i></Link></li>
                                    <li><Link to="#"><i className='bx bxl-twitter' ></i></Link></li>
                                    <li><Link to="#"><i className='bx bxl-pinterest' ></i></Link></li>
                                </ul>
                                <select className="form-select" aria-label="Default select example">
                                    <option >English</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-12">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="footer-link">
                                        <h4>Company</h4>
                                        <ul>
                                            <li><Link to="#">About us</Link></li>
                                            <li><Link to="#">Careers</Link></li>
                                            <li><Link to="#">Security</Link></li>
                                            <li><Link to="#">Status</Link></li>
                                            <li><Link to="#">Terms & privacy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="footer-link">
                                        <h4>Resources</h4>
                                        <ul>
                                            <li><Link to="#">Help centre</Link></li>
                                            <li><Link to="#">Pricing</Link></li>
                                            <li><Link to="#">Blog</Link></li>
                                            <li><Link to="#">Community</Link></li>
                                            <li><Link to="#">Integrations</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="footer-link">
                                        <h4>Commercial</h4>
                                        <ul>
                                            <li><Link to="#">Small Business</Link></li>
                                            <li><Link to="#">Business Plan Loans</Link></li>
                                            <li><Link to="#">Franchise</Link></li>
                                            <li><Link to="#">Fundraising Business</Link></li>
                                            <li><Link to="#">General Business Plans</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="footer-link">
                                        <h4>Immigration</h4>
                                        <ul>
                                            <li><Link to="#">Start-Up</Link></li>
                                            <li><Link to="#">Self-Employed</Link></li>
                                            <li><Link to="#">LMIA Owner</Link></li>
                                            <li><Link to="#">Intra-company</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right gray-bg"><span>&copy; <script>
                var CurrentYear = new Date().getFullYear()
                document.write(CurrentYear)
            </script> PlanCrafter. All rights reserved. </span></div>
        </footer>
    )
}

export default Footer