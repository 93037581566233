import { combineReducers } from 'redux';

import loaderReducer from './common/loaderReducer'
import userReducer from './userReducer' 
import { ACTIONS } from '../../_config';

const appReducer = combineReducers({
  loader: loaderReducer,
  user: userReducer,  
})

const rootReducer = (state: any, action: any) => {
  if (action.type === ACTIONS.RESET_REDUCER) {
    state = undefined;
    localStorage.removeItem('persist:root');
  }

  return appReducer(state, action)
}

export default rootReducer