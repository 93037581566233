import axios from "axios";
import { API_URL, SAGA_ACTIONS } from "../../../_config";
import { UpdateWithVariables } from "../../functions";
import { CallApi } from "../../../store/api/callApi";
import { useApiCall } from "../common/appApiCallHook";

export function useMyAccountApi() {
  const callApi = useApiCall();


  const getMyBusinessPlan = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.ACCOUNT.MY_BUSINESS_PLAN, data, onSuccess, onError);
  };

  const updateProfile = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.ACCOUNT.MY_ACCOUNT_UPDATE, data, onSuccess, onError);
  };


  return {
    getMyBusinessPlan, updateProfile
  };
}