import { useEffect, useState } from "react";
import Footer from "../../components/layouts/footer";
import { API_URL } from "../../_config";
import { useToken } from "../../_common/hooks/authHook";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Error from "../../components/formFieldError";
import Nav from "../../components/layouts/nav";
import { useMyAccountApi } from "../../_common/hooks/api/myAccountApiHook";

const MyAccount = () => {
    const myAccAPI = useMyAccountApi();
    const [profileDetails, setProfileDetails] = useState<any>(null);
    let tokenData: any = useToken();
    const [userimage, setUserimages] = useState<string | "/images/img/noUser.svg">("/images/img/noUser.svg");

    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm();

    const getMyProfileDetails = async () => {
        try {
            const response = await fetch(API_URL.ACCOUNT.MY_ACCOUNT, {
                headers: { Authorization: `Bearer ${tokenData}` }
            });
            const resp = await response.json();
            setProfileDetails(resp.data);
            setUserimages(resp.data?.user?.avatar?.thumb)
            let resetValues = {
                first_name: resp.data?.user?.first_name,
                last_name: resp.data?.user?.last_name,
                job_title: resp.data?.job_title,
                department: resp.data?.department,
            }
            reset(resetValues);
        } catch (error) {
            console.log('Error occurred while making the API request.');
            setProfileDetails([])
        }
    }

    useEffect(() => {
        getMyProfileDetails();
    }, [])


    const updateProfile = (params: any) => {
        return new Promise((resolve, reject) => {

            myAccAPI.updateProfile(params, (message: any, resp: any) => {
                resolve(message)
            }, (message: any, data: any) => {
                reject(message)
                console.log('error Response:==', data);
                if (data) {
                    Object.keys(data).map((key) => { // key is the field name
                        setError(key, {
                            type: "manual",
                            message: data[key]
                        })
                    })
                }
            })



            // fetch(API_URL.ACCOUNT.MY_ACCOUNT_UPDATE, {
            //     method: 'PUT',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': `Bearer ${tokenData}`
            //     },
            //     body: JSON.stringify(params),
            // })
            //     .then(response => response.json())
            //     .then(resp => {
            //         if (resp?.status == 200) { // Success
            //             resolve(resp.message);
            //         } else {
            //             reject(resp.message);
            //             const errorResp = resp?.data;
            //             if (errorResp) {
            //                 Object.keys(errorResp).map((key) => {
            //                     setError(key, {
            //                         type: "manual",
            //                         message: errorResp[key]
            //                     })
            //                 })
            //             }
            //         }
            //     })
            //     .catch(error => {
            //         // Handle error
            //         console.error('Error:', error);
            //         reject(error.message);
            //     });
        })
    }


    const saveFormHandler = async (data: any) => {
        data.id = profileDetails?.user?.id;
        toast.promise(
            updateProfile(data), {
            loading: 'Updating Profile...',
            success: (msg: any) => {
                getMyProfileDetails();
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    const updateAvatar = (params: any) => {
        return new Promise((resolve, reject) => {
            fetch(API_URL.ACCOUNT.MY_AVATAR_UPDATE, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tokenData}` },
                body: JSON.stringify(params),
            })
                .then(response => response.json())
                .then(resp => {
                    if (resp?.status == 200) { // Success
                        resolve(resp.message);
                    } else {
                        reject(resp.message); 
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error:', error);
                    reject(error.message);
                });
        })
    }

    const handleProfilePicChange = (event: any) => {
        // Access the selected file(s) from event.target.files
        const selectedFile = event.target.files[0];

        // Do something with the selected file, e.g., display its name
        console.log("Selected File:", selectedFile.name);
        if (selectedFile) {
            const reader = new FileReader();

            reader.onloadend = () => {
                // Base64 string representation of the selected file
                const base64String = reader.result as string | null;
                console.log("Base64 String:", base64String);
                if (base64String !== null) {
                    setUserimages(base64String);

                    toast.promise(
                        updateAvatar({'avatar': base64String}), {
                        loading: 'Uploading...',
                        success: (msg: any) => {
                            return <b>{msg}</b>
                        },
                        error: (msg: any) => { return <b>{msg}</b> },
                    });
                }

                // You can use the base64String as needed (e.g., set it in the state)
            };

            // Read the selected file as a Data URL (Base64)
            reader.readAsDataURL(selectedFile);
        }
    };

    return (

        <>
            <Nav />
            <div className="body-part gray-bg mt-105">
                <div className="container text-center">
                    <h2 className="page-title">Account <span>Information</span></h2>
                    <p className="tag-line">Manage your profile</p>

                    <div className="profile-box">
                        <div className="profile-box-title">
                            <div className="profile-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                                    <path d="M13 17C17.4183 17 21 13.4183 21 9C21 4.58172 17.4183 1 13 1C8.58172 1 5 4.58172 5 9C5 13.4183 8.58172 17 13 17Z" stroke="#D1D1D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1 24C3.42125 19.8162 7.81875 17 13 17C18.1813 17 22.5787 19.8162 25 24" stroke="#D1D1D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="profile-text">Your Profile</div>
                        </div>
                        <div className="profile-box-data">
                            <div className="profile-box-left">
                                <div className="profile-image">
                                    <span><img src={userimage} alt={profileDetails?.user?.full_name} title={profileDetails?.user?.full_name} /></span>
                                    <em><img src="/images/img/camera-icon.svg" alt="" />
                                    <Controller
                                        defaultValue={""}
                                        control={control}
                                        name="profilePic"
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                        }) => (
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => {
                                                    handleProfilePicChange(e);
                                                }}
                                            />
                                        )}
                                    />
                                    </em>
                                </div>
                                <div className="profile-data">
                                    <h2>{(profileDetails?.user?.full_name) ?? 'Loading...'}</h2>
                                    <h3>{profileDetails?.user?.email}</h3>
                                </div>
                            </div>
                            <div className="profile-box-right">
                                <form onSubmit={handleSubmit(saveFormHandler)} >
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="mb-3 float-input">
                                                <label className="form-label">First Name <span className="text-red-500">*</span></label>
                                                <Controller
                                                    defaultValue={""}
                                                    control={control}
                                                    name={`first_name`}
                                                    rules={{ required: 'This field is required' }}
                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                        <input type="text" name={name} onChange={onChange} value={value} className="form-control" autoComplete="off" placeholder="First Name" spellCheck="false" data-ms-editor="true" />
                                                    )}
                                                />
                                                <Error error={errors[`first_name`]} customClass="text-xs" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mb-3 float-input">
                                                <label className="form-label">Last Name <span className="text-red-500">*</span></label>
                                                <Controller
                                                    defaultValue={""}
                                                    control={control}
                                                    name={`last_name`}
                                                    rules={{ required: 'This field is required' }}
                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                        <input type="text" name={name} onChange={onChange} value={value} className="form-control" autoComplete="off" placeholder="Last Name" spellCheck="false" data-ms-editor="true" />
                                                    )}
                                                />
                                                <Error error={errors[`last_name`]} customClass="text-xs" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mb-3 float-input">
                                                <label className="form-label">Job Title <span className="text-red-500">*</span></label>
                                                <Controller
                                                    defaultValue={""}
                                                    control={control}
                                                    name={`job_title`}
                                                    rules={{ required: 'This field is required' }}
                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                        <input type="text" name={name} onChange={onChange} value={value} className="form-control" autoComplete="off" placeholder="Job Title" spellCheck="false" data-ms-editor="true" />
                                                    )}
                                                />
                                                <Error error={errors[`job_title`]} customClass="text-xs" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mb-3 float-input">
                                                <label className="form-label">Your Department <span className="text-red-500">*</span></label>
                                                <Controller
                                                    defaultValue={""}
                                                    control={control}
                                                    name={`department`}
                                                    rules={{ required: 'This field is required' }}
                                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                        <input type="text" name={name} onChange={onChange} value={value} className="form-control" autoComplete="off" placeholder="Your Department" spellCheck="false" data-ms-editor="true" />
                                                    )}
                                                />
                                                <Error error={errors[`department`]} customClass="text-xs" />
                                            </div>
                                        </div>
                                        {/* <div className="col-6">
                                            <div className="mb-3 float-input">
                                                <label className="form-label">Select your department</label>
                                                <select className="form-select" aria-label="Default select example">
                                                    <option selected>Open this select menu</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="btn-wrap">
                                        <button type="submit" className="update-btn">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default MyAccount; 